import type { ContactFormInputsType } from "~/lib/schema";

import { useState } from "react";
import classNames from "classnames";
import { Form, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { ContactFormInputs } from "~/lib/schema";

import RightArrow from "~/components/icons/RightArrow";
import Loading from "~/components/icons/Loading";

const inputClassNames = classNames(
    "mb-[1px] block w-full bg-white py-2 text-[13px]/[16px] font-medium placeholder:italic text-black placeholder:text-gray-600 focus:mb-0 focus:border-b-2 focus:outline-none",
    "border-b border-black",
    "focus:mb-0 focus:border-b-2 focus:outline-none",
    "aria-[invalid=true]:border-[#FF81BE]",
);
type FromControlProps = {
    id: string;
    label: string;
    children: React.ReactNode;
    error?: string;
};
export const FormControl = ({
    children,
    id,
    label,
    error,
}: FromControlProps) => {
    return (
        <>
            <label htmlFor={id} className="sr-only">
                {label}
            </label>
            <div className="mt-2.5">
                {children}
                {error && (
                    <p className="mt-1.5 text-[13px]/[16px] font-medium italic text-[#FF81BE]">
                        {error}
                    </p>
                )}
            </div>
        </>
    );
};

export default function ContactForm() {
    const [responseMessage, setResponseMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const {
        register,
        control,
        formState: { isSubmitSuccessful, errors, isSubmitting },
    } = useForm<ContactFormInputsType>({
        resolver: zodResolver(ContactFormInputs),
    });

    return (
        <Form
            className="sm:order-last lg:w-6/12 lg:self-center lg:px-8 xl:pl-0 xl:pr-20"
            method="post"
            action={"/api/contact"}
            onSuccess={async ({ response }) => {
                const data = await response.json();
                if (data.message) {
                    setResponseMessage("Message correctement envoyé !");
                }
            }}
            onError={async ({ response }) => {
                if (response?.status === 400) {
                    const data = await response.json();
                    setErrorMessage(data.message);
                }
                setErrorMessage("Une erreur est survenue, veuillez réessayer.");
            }}
            control={control}
        >
            <div className="">
                <div className="relative overflow-hidden">
                    <p>{!!isSubmitSuccessful}</p>
                    <div
                        className={classNames(
                            "grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2",
                            "w-auto transition-all duration-200 ease-in-out",
                            {
                                "-translate-y-full": isSubmitSuccessful,
                            },
                        )}
                    >
                        <div className="sm:col-span-2">
                            <FormControl
                                label="Prénom + nom"
                                id="fullname"
                                error={errors.fullname?.message}
                            >
                                <input
                                    type="text"
                                    id="fullname"
                                    autoComplete="name"
                                    placeholder="Prénom + nom"
                                    className={inputClassNames}
                                    required
                                    aria-invalid={!!errors.fullname}
                                    {...register("fullname", {
                                        required: true,
                                    })}
                                />
                            </FormControl>
                        </div>
                        <div className="">
                            <FormControl
                                label="Email"
                                id="email"
                                error={errors.email?.message}
                            >
                                <input
                                    type="email"
                                    id="email"
                                    autoComplete="email"
                                    placeholder="Email"
                                    className={inputClassNames}
                                    required
                                    aria-invalid={!!errors.email}
                                    {...register("email", { required: true })}
                                />
                            </FormControl>
                        </div>
                        <div className="">
                            <FormControl
                                label="Téléphone"
                                id="phone"
                                error={errors.phone?.message}
                            >
                                <input
                                    type="tel"
                                    id="phone"
                                    autoComplete="tel"
                                    placeholder="Téléphone"
                                    className={inputClassNames}
                                    required
                                    aria-invalid={!!errors.phone}
                                    {...register("phone", {
                                        required: true,
                                        maxLength: 30,
                                    })}
                                />
                            </FormControl>
                        </div>
                        <div className="sm:col-span-2">
                            <FormControl
                                label="Message"
                                id="message"
                                error={errors.message?.message}
                            >
                                <textarea
                                    id="message"
                                    rows={4}
                                    placeholder="Un message, un projet, une aventure. Écrivez-moi !"
                                    aria-invalid={!!errors.message}
                                    {...register("message", {
                                        required: true,
                                        min: 15,
                                    })}
                                    className={inputClassNames}
                                ></textarea>
                            </FormControl>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="acceptance">
                                <div className="mt-2.5">
                                    <input
                                        type="checkbox"
                                        id="acceptance"
                                        className={classNames(
                                            "bg-white py-2 focus:mb-0 focus:border-b-2 focus:outline-none",
                                            "border-b border-black",
                                            "focus:mb-0 focus:border-b-2 focus:outline-none",
                                            "aria-[invalid=true]:border-[#FF81BE]",
                                            "align-middle",
                                        )}
                                        // aria-invalid={!!errors.acceptance}
                                        {...register("acceptance", {
                                            required: true,
                                        })}
                                    />
                                    <span className="pl-1 text-[13px]/[16px] font-medium text-black">
                                        J'accepte la politique de
                                        confidentialité
                                    </span>
                                </div>
                                {errors.acceptance?.message && (
                                    <p className="mt-1.5 text-[13px]/[16px] font-medium italic text-[#FF81BE]">
                                        {errors.acceptance?.message}
                                    </p>
                                )}
                            </label>
                        </div>
                    </div>
                    <div
                        className={classNames(
                            "absolute px-8 py-12",
                            "transition-all duration-200 ease-in-out",
                            {
                                "top-2/4 -translate-y-[50%]":
                                    isSubmitSuccessful,
                                "bottom-0 translate-y-full":
                                    !isSubmitSuccessful,
                            },
                        )}
                    >
                        Merci pour votre message. Je vous répondrai dans les
                        plus brefs délais.
                    </div>
                </div>

                <div className="relative mt-8 flex items-center justify-between overflow-hidden">
                    <div>
                        {errorMessage && (
                            <p className="mt-1.5 text-[13px]/[16px] font-medium italic text-[#FF81BE]">
                                {errorMessage}
                            </p>
                        )}
                        {(isSubmitSuccessful || responseMessage) && (
                            <p className="mt-1.5 text-[13px]/[16px] font-medium italic text-[#437153]">
                                {responseMessage}
                            </p>
                        )}
                    </div>
                    <button
                        type="submit"
                        className={classNames(
                            "group/button-fill flex items-center gap-2 rounded-full border border-black bg-black px-[22px] py-2 font-vvds-fifties-scond text-base/[14px] tracking-[0.01em] text-white shadow-sm hover:bg-white hover:text-black",
                            "w-auto transition-all duration-200 ease-in-out",
                            {
                                "pointer-events-none":
                                    isSubmitSuccessful || isSubmitting,
                                "-translate-y-full": isSubmitSuccessful,
                            },
                        )}
                        disabled={isSubmitSuccessful || isSubmitting}
                    >
                        <span className="uppercase">Envoyer</span>
                        {isSubmitting ? <Loading /> : <RightArrow />}
                    </button>
                </div>
            </div>
        </Form>
    );
}
