import { z } from "zod";

export const ContactFormInputs = z
    .object({
        fullname: z
            .string()
            .min(1, { message: "Veuillez entrer votre nom et prénom" }),
        email: z
            .string({ message: "Veuillez entrer votre email" })
            .email({ message: "Veuillez entrer un email valide" }),
        phone: z.string().min(1, {
            message: "Veuillez entrer votre numéro de téléphone",
        }),
        message: z.string().min(15, { message: "Veuillez entrer un message" }),
        acceptance: z
            .boolean()
            .refine((value) => value === true, {
                message: "Veuillez accepter les conditions",
            }),
    })
    .required();

export type ContactFormInputsType = z.infer<typeof ContactFormInputs>;
